<template>
  <div v-if="userData.vendor">
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
          <li v-for="value in values" :key="value">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <!-- User Info: Left col -->
      <b-col md="4" class="d-flex justify-content-between flex-column">
        <b-card class="h-100 cardH position-relative">
          <!-- User Avatar & Action Buttons -->

          <b-row class="mt-2">
            <!-- user profile image -->
            <b-col
              md="5"
              class="justify-content-center align-items-center text-center"
            >
              <CoolLightBox
                :items="profileArr"
                :index="imgIndex"
                @close="imgIndex = null"
              >
              </CoolLightBox>
              <b-img
                @click="imgIndex = 0"
                rounded
                v-if="userData.vendor"
                :src="userData.vendor.profile_picture"
                :alt="userData.vendor.firstname"
                style="height: 200px; width: auto;"
              />
            </b-col>

            <b-col lg="7" md="9" >
              <div class="d-flex justify-content-start">
                <div class="d-flex flex-column ml-1">
                  <div class="">
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >First Name:
                      </span>
                      {{ userData.vendor.firstname }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Last Name:
                      </span>
                      {{ userData.vendor.lastname }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Store Name:
                      </span>
                      {{ userData.vendor.storename }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Email:
                      </span>
                      {{ userData.vendor.email }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Code:
                      </span>
                      {{ userData.vendor.code }}
                    </h5>
                    <h5 style="line-height: 2;">
                      <span style="font-weight: bold; color: #8f86f3;"
                        >Status:
                      </span>
                      <b-badge variant="primary"> {{ status }} </b-badge>
                    </h5>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-col lg="12" md="12" class="mt-3">
            <b-button
              v-if="
                userData.vendor.type == 'company' ||
                userData.vendor.type == 'personal'
              "
              style=""
              class="show-btn position-absolute"
              size="md"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              type="submit"
              :to="{ path: '/Vendor/Show/' + userData.vendor.id }"
            >
              Show Vendor <feather-icon style="" icon="ArrowRightIcon" />
            </b-button>
            <b-button
              v-else
              style=""
              class="show-btn position-absolute"
              size="md"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              type="submit"
              :to="{ path: '/Vendor/Show/' + userData.vendor.id }"
            >
              Show Vendor <feather-icon style="" icon="ArrowRightIcon" />
            </b-button>
          </b-col>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card>
          <card-statistics-group :statisticsItems="statistics" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import OrdersTable from '@/Components/OrdersTable'
import ProductsTable from '@/Components/PrdouctsTable'
import OffersTable from '@/Components/ProductsWithOfferTable'
import VendorPayout from '@/Components/VendorPayouts'
import CardStatisticsGroup from '../CardStatisticsGroup.vue'

import { required, email, integer } from '@validations'

// import axios from '@/libs/axios'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
export default {

  mixins: [heightTransition],
  components: {
    CoolLightBox,
    OrdersTable,
    ProductsTable,
    OffersTable,
    CardStatisticsGroup,
    VendorPayout,
  },
  
  data() {
    return {
      officialUpdateShow: false,
      officialUpdateID: null,
      required,
      email,
      integer,
      mob: false,
      phone: null,
      mobileNumberData: {
        phoneNumbers: [
          {
            mobile: null,
          },
        ],
      },
      official: false,
      typeFileOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      addOfficial: {
        name: null,
        file: null,
        number: null,
      },
      updateOfficial: {
        file: null,
        number: null,
      },
      addPayout: {
        name: null,
        file: null,
        number: null,
      },
      typeFileSelect: null,
      payouts: false,
      productsClick: false,
      offersClick: false,
      payoutsClick: false,
      showID: false,
      per: null,
      userData: {},
      errors_back: [],
      showDismissibleAlert: false,
      id: 0,
      status: '',
      area: '',
      delUser: true,
      imgIndex: null,
      img2Index: null,
      img3Index: null,
      profileArr: [],
      frontArr: [],
      backArr: [],
      statistics: [],
      comm_id: '',
      deactive: '0',
      deletedVendor: '0',
      selectedTwo: {
        products: [],
      },
      selected: [],
      options: [],
      loadMore: true,
    }
  },
  created() {
    this.id = this.$route.params.id
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('vendor-wallet/' + this.id + '/show')
        .then((result) => {
          this.userData = result.data.data
          this.status = this.userData.vendor.status.name
          this.area = this.userData.vendor.area.name
          this.profileArr.push(this.userData.vendor.profile_picture)
          this.frontArr.push(this.userData.vendor.idfront_picture)
          this.backArr.push(this.userData.vendor.idback_picture)
          // set commision here
          this.statistics.length = 5
          this.statistics[0] = {
            icon: 'DollarSignIcon',
            color: 'light-primary',
            title: this.userData.total_sale,
            subtitle: 'Total Sale',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics[1] = {
            icon: 'PercentIcon',
            color: 'light-info',
            title: this.userData.margin_amount,
            subtitle: 'Rate',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics[2] = {
            icon: 'TrendingUpIcon',
            color: 'light-success',
            title: this.userData.received_amount,
            subtitle: 'Received Amount',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics[3] = {
            icon: 'BoxIcon',
            color: 'light-danger',
            title: this.userData.available_amount,
            subtitle: 'available Amount',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics[4] = {
            icon: 'TrendingDownIcon',
            color: 'light-info',
            title: this.userData.pending_amount,
            subtitle: 'Remaining Amount',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics[5] = {
            icon: 'ArrowUpIcon',
            color: 'light-warning',
            title: this.userData.last_paid,
            subtitle: 'Last Paied',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics[6] = {
            icon: 'DollarSignIcon',
            color: 'light-primary',
            title: this.userData.offer_amount,
            subtitle: 'Offer Amount',
            customClass: 'mb-2 mb-xl-0',
          }
          this.statistics[7] = {
            icon: 'BoxIcon',
            color: 'light-info',
            title: this.userData.bundle_offer,
            subtitle: 'Bundle Offer',
            customClass: 'mb-2 mb-xl-0',
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style scoped>
.show-btn {
  bottom: 0px !important;
  right: 0;
  left: 0;
}
</style>
